<template>
  <section id="services" class="wow animate__fadeIn">
    <div class="container">
      <div class="row row-title">
        <div class="col-12 text-center">
          <div class="sub-title alt-font">Mida me teeme?</div>
          <h5 class="alt-font">Meie teenused</h5>
        </div>
      </div>

      <div class="row row-cols-1 row-cols-lg-3 row-cols-sm-2 justify-content-center">
        <template v-for='(service, index) in services' :key='index'>
          <div class="service-item col-12 col-lg-4 col-md-6 col-sm-8 wow animate__fadeInRight" data-wow-delay="0.2s">
            <div class="feature-box text-start">
              <div class="content">
                <div class="service-title alt-font">
                  {{ service.title }}
                </div>
                <p>
                  {{ service.description }}
                </p>
                <div class="separator small"></div>
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>
  </section>
</template>

<script>

export default {
  name: 'HomeServices',
  data() {
    return {
      services: [
        {
          title: 'Talentide värbamine',
          description: 'Pole oluline, kas oled start-up või turul juba tuntud firma - minu eesmärk on leida sinu meeskonda liige, kes toob oma isiksuse ja teotahtega väärtust kogu ettevõttele. Arvestan alati nii ettevõtte kui ka töötaja profiiliga. Olen spetsialiseerunud IT värbamisprojektidele, kuid esita mulle väljakutse ja ma näitan, et leian talendi sinu meeskonda.'
        },
        {
          title: 'Konsultatsioon',
          description: 'Olen personalipartner, kes tuleb appi mistahes personali toimingute ja protsesside juures. Vaatame üle, mis on olemas, mis on vajadus ning mida on vaja muuta. Paneme paika eesmärgid ja asume toimetama. Minu poole võib pöörduda nii ühekordse konsultatsiooni kui ka pikema koostöö eesmärgil.'
        },
        {
          title: 'Inimeste juhtimine',
          description: 'Pean tähtsaks inimsuhteid ning kokkuleppeid. Usun, et inimesi saab juhtida läbi avatuse ning lugupidamise. Just nendest väärtustest lähtun ka oma klientide puhul. Rahulolev töötaja on motiveeritud.'
        }
      ]
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/scss/home/_services.scss';
</style>