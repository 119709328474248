<template>
  <footer class="footer-strip padding-50px-tb sm-padding-30px-tb">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-md-3 text-center text-lg-start sm-margin-20px-bottom">
          <router-link to="/" class="logo">
            <img class="footer-logo" :src="logoUrl" :alt="companyName">
          </router-link>
        </div>
        <div class="col-md-6 text-center text-small alt-font sm-margin-10px-bottom">
          &copy; {{ getCurrentYear }} <a :href="copyrightLing" target="_blank">{{ companyNameVerbose }} | 16023782 </a>
        </div>
        <div class="col-md-3 text-center text-lg-end">
          <div class="social-icon-container">
            <ul class="list-unstyled small-icon mb-0">
              <li>
                <a class="linkedIn" :href="linkedInUrl" target="_blank">
                  <font-awesome-icon :icon="['fab', 'linkedin-in']"/>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>

export default {
  name: 'PageFooter',
  data() {
    return {
      companyName: this.company.name,
      companyNameVerbose: this.company.nameVerbose,
      linkedInUrl: this.company.linkedInUrl,
      copyrightLing: 'https://www.e-krediidiinfo.ee/16023782-PEOPLEFY%20O%C3%9C'
    }
  },
  computed: {
    getCurrentYear() {
      return new Date().getFullYear()
    },
    logoUrl() {
      return require('@/assets/images/logo/min/peoplefy-logo-png-2-black-without-text.webp')
    }
  }
}
</script>

<style lang="scss">
.footer-logo {
  max-height: 70px;
}
</style>