<template>
  <section id="contact" class="wow animate__fadeIn">
    <div class="container">
      <div class="row row-title">
        <div class="col-12 text-center">
          <div class="sub-title alt-font">
            Vajad talente, et oma ideid ellu viia?
          </div>
          <h5 class="alt-font">
            Oleme emaili või telefonikõne kaugusel
          </h5>
        </div>
      </div>

      <div class="row align-items-center justify-content-center">
        <div class="col-12 col-md-2 wow animate__fadeInUp">
          <div class="profile-image-container">
            <img class="profile-image" :src="profileImgUrls.jane" alt="Jane Ilm">
          </div>
        </div>
        <div class="col-12 col-md-2 contact-info-column wow animate__fadeInUp">
          <ul class="list-unstyled m-0">
            <li><strong>Jane Ilm</strong> </li>
            <li><strong>Manager</strong> </li>
            <li><a href="mailto:jane@peoplefy.ee">jane@peoplefy.ee</a></li>
            <li><a href="tel:+37256810300">+372 56 810 300</a></li>
          </ul>
        </div>
        <div class="col-12 col-md-2 wow animate__fadeInUp">
          <div class="profile-image-container">
            <img class="profile-image" :src="profileImgUrls.leeve" alt="Jane Ilm">
          </div>
        </div>
        <div class="col-12 col-md-2 contact-info-column wow animate__fadeInUp">
          <ul class="list-unstyled m-0 last">
            <li><strong>Leeve Karmo</strong> </li>
            <li><strong>Partner</strong> </li>
            <li><a href="tel:+37256646802">+372 56 646 802</a></li>
          </ul>
        </div>
      </div>
    </div>
  </section>
</template>

<script>

export default {
  name: 'HomeContact',
  computed: {
    profileImgUrls() {
      return {
        'jane': require('@/assets/images/jane-ilm.webp'),
        'leeve': require('@/assets/images/leeve-karmo.webp')
      }
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/scss/home/_contact.scss';
</style>