<template>
  <HomeHero></HomeHero>
  <HomeServices></HomeServices>
  <HomePortfolio></HomePortfolio>
  <HomeContact></HomeContact>
</template>

<script>
import HomeServices from "@/components/home/HomeServices";
import HomePortfolio from "@/components/home/HomePortfolio";
import HomeContact from "@/components/home/HomeContact";
import HomeHero from "@/components/home/HomeHero";

export default {
  name: 'ViewHome',
  components: {
    HomeHero,
    HomeContact,
    HomePortfolio,
    HomeServices
  }
}
</script>
