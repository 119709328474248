<template>
  <!-- start hero section -->
  <section id="home" class="p-0 parallax mobile-height wow animate__fadeIn" data-parallax-background-ratio="0.5" :style="heroBgStyle">

    <div class="opacity-extra-medium bg-light-gray"></div>

    <div id="full-screen-hero" class="container position-relative full-screen">
      <div class="row h-100">
        <div class="col-12 hero-container">
          <div class="hero-container-inner">
            <h1 class="hero-heading alt-font">
              Sinu äri <br />
              personalipartner
            </h1>

            <p class="hero-paragraph sm-margin-15px-bottom">
              Peoplefy on professionaalne personali värbamisagentuur Tallinnas, <br/>
              mille peamine eesmärk on leida parimad töötajad ja tiimiliikmed.
            </p>

            <div class="hero-buttons">
              <a href="#portfolio" class="btn transparent alt-font">Vaata tehtuid töid</a>
              <a href="#contact" class="btn alt-font">Võta ühendust</a>
            </div>
          </div>
          </div>
      </div>

    </div>
  </section>
  <!-- end hero section -->
</template>

<script>
export default {
  name: 'HomeHero',
  computed: {
    heroBgStyle () {
      const imgUrl = require('@/assets/images/hero-bg.webp');
      return `background-image:url(${imgUrl});`
    }
  }
}
</script>

<style lang="scss">
  @import '@/assets/scss/home/_hero.scss';
</style>