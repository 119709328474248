<template>
  <PageHeader></PageHeader>
  <router-view></router-view>
  <PageFooter></PageFooter>
</template>

<script>
import PageFooter from "@/components/PageFooter";
import PageHeader from "@/components/PageHeader";

export default {
  name: 'App',
  components: {
    PageHeader,
    PageFooter
  }
}
</script>
