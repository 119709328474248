import {createApp} from 'vue'
import App from './App.vue'
import router from './router'

import {library} from "@fortawesome/fontawesome-svg-core";
import {faLinkedinIn} from "@fortawesome/free-brands-svg-icons";
import {faMagnifyingGlass} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";

import "@/assets/scss/style.scss"

library.add(faLinkedinIn, faMagnifyingGlass);

const app = createApp(App);

app.config.globalProperties.company = {
    name: 'Peoplefy',
    nameVerbose: 'Peoplefy OÜ',
    linkedInUrl: 'https://www.linkedin.com/company/peoplefy-ou/',
}

app
    .use(router)
    .component("font-awesome-icon", FontAwesomeIcon)
    .mount('#app')