<template>
  <header>
    <nav class="navbar navbar-top">
      <div class="container nav-header-container">
        <div class="col pl-0 pl-md-3">
          <router-link to="/" class="logo">
            <img :src="logoUrl" :alt="companyName">
          </router-link>
        </div>
        <div class="col-auto pe-0">
          <div class="social-icon-container">
            <a class="linkedIn" :href="linkedInUrl" target="_blank" title="linkedIn">
              <font-awesome-icon :icon="['fab', 'linkedin-in']" />
            </a>
          </div>
        </div>
      </div>
    </nav>
  </header>
</template>

<script>

export default {
  name: 'PageHeader',
  data() {
    return {
      companyName: this.company.name,
      linkedInUrl: this.company.linkedInUrl
    }
  },
  computed: {
    logoUrl () {
      return require('@/assets/images/logo/min/peoplefy-logo-png-2-black-with-text.webp')
    }
  }
}
</script>

<style lang="scss">
  @import '@/assets/scss/_header.scss';
</style>