<template>
  <section id="portfolio" class="wow animate__fadeIn">
    <div class="container">

      <div class="row row-title">
        <div class="col-12 text-center">
          <div class="sub-title alt-font">Portfoolio</div>
          <h5 class="alt-font">
            Meeskonnad, kes on liikmete võrra tugevamad 👇
          </h5>
        </div>
      </div>

      <div class="row">
        <div class="col-12 overflow-hidden">
          <ul id="portfolio-wrapper" class="portfolio-wrapper portfolio-grid">
            <li class="grid-sizer"></li>

            <template v-for='(job, index) in jobs' :key='index'>
              <li class="grid-item wow animate__fadeInUp">
                <figure>
                  <div class="portfolio-item">
                    <img :src="job.clientLogo" :alt="job.client"/>

                    <span class="separator"></span>

                    <div class="portfolio-item-title alt-font">
                      {{job.title}}
                    </div>
                    <div class="portfolio-icon">
                      <a :title="job.jobFullTile" :href="job.jobAdImg" target="_blank">
                        <font-awesome-icon :icon="['fa-solid', 'magnifying-glass']" class="text-extra-dark-gray"/>
                      </a>
                    </div>
                  </div>
                </figure>
              </li>
            </template>

          </ul>
        </div>
      </div>
    </div>
  </section>
</template>

<script>

export default {
  name: 'HomePortfolio',
  data() {
    return {
      jobs: [
        {
          client: 'Guardtime',
          title: 'Software Engineer for Digital Money Platform',
          jobFullTile: 'Guardtime - Software Engineer for Digital Money Platform',
          clientLogo: require('@/assets/images/clients/peoplefy-client-guardtime.svg'),
          jobAdImg: require('@/assets/images/jobs/guardtime/engineer-digital-money.jpg')
        },
        {
          client: 'Guardtime',
          title: 'System Analyst',
          jobFullTile: 'Guardtime - System Analyst',
          clientLogo: require('@/assets/images/clients/peoplefy-client-guardtime.svg'),
          jobAdImg: require('@/assets/images/jobs/guardtime/system-analyst.jpg')
        },
        {
          client: 'Guardtime',
          title: 'Software Engineer for Back-End Development',
          jobFullTile: 'Guardtime - Software Engineer for Back-End Development',
          clientLogo: require('@/assets/images/clients/peoplefy-client-guardtime.svg'),
          jobAdImg: require('@/assets/images/jobs/guardtime/engineer-backend.jpg')
        },
        {
          client: 'Raintree',
          title: 'Senior Node.js Engineer',
          jobFullTile: 'Raintree - Senior Node.js Engineer',
          clientLogo: require('@/assets/images/clients/peoplefy-client-raintree.png'),
          jobAdImg: require('@/assets/images/jobs/raintree/engineer.png')
        },
        {
          client: 'Raintree',
          title: 'Senior Devops Engineer',
          jobFullTile: 'Raintree - Senior Devops Engineer',
          clientLogo: require('@/assets/images/clients/peoplefy-client-raintree.png'),
          jobAdImg: require('@/assets/images/jobs/raintree/devops.png')
        },
        {
          client: 'Solid World',
          title: 'Senior Web3 Developer',
          jobFullTile: 'Solid World - Senior Web3 Developer',
          clientLogo: require('@/assets/images/clients/peoplefy-client-solid-world.svg'),
          jobAdImg: require('@/assets/images/jobs/solidworld/senior-web3-developer.png')
        },
        {
          client: 'Mountbirch',
          title: 'Front-end Developer',
          jobFullTile: 'Mountbirch - Front-end Developer',
          clientLogo: require('@/assets/images/clients/peoplefy-client-mountbirch.svg'),
          jobAdImg: require('@/assets/images/jobs/mountbirch/front-end-developer.jpg')
        },
        {
          client: 'eAgronom',
          title: 'Data Scientist / Technical Product Manager',
          jobFullTile: 'eAgronom - Data Scientist / Technical Product Manager',
          clientLogo: require('@/assets/images/clients/peoplefy-client-eagronom.svg'),
          jobAdImg: require('@/assets/images/jobs/eAgronom/data-scientist-product-manager.png')
        },
        {
          client: 'Sportland',
          title: 'Senior developer',
          jobFullTile: 'Sportland - Senior developer',
          clientLogo: require('@/assets/images/clients/peoplefy-client-sportland.webp'),
          jobAdImg: require('@/assets/images/jobs/sportland/senior-developer.jpg')
        },
        {
          client: 'Holm',
          title: 'Devops Engineer',
          jobFullTile: 'Holm - Devops Engineer',
          clientLogo: require('@/assets/images/clients/peoplefy-client-holm.svg'),
          jobAdImg: require('@/assets/images/jobs/holm/devops-engineer.jpg')
        },
        {
          client: 'Holm',
          title: 'Java Developer',
          jobFullTile: 'Holm - Java Developer',
          clientLogo: require('@/assets/images/clients/peoplefy-client-holm.svg'),
          jobAdImg: require('@/assets/images/jobs/holm/java-developer.jpg')
        },
        {
          client: 'eAgronom',
          title: 'Senior Front-end Blockchain developer',
          jobFullTile: 'eAgronom - Senior Front-end Blockchain developer',
          clientLogo: require('@/assets/images/clients/peoplefy-client-eagronom.svg'),
          jobAdImg: require('@/assets/images/jobs/eAgronom/senior-front-blockchain-dev.jpg')
        },
        {
          client: 'MindTitan',
          title: 'Senior Software Engineer / Architect',
          jobFullTile: 'MindTitan - Senior Software Engineer / Architect',
          clientLogo: require('@/assets/images/clients/peoplefy-client-mindtitan.svg'),
          jobAdImg: require('@/assets/images/jobs/mindtitan/engineer-architect.png')
        },
        {
          client: 'MindTitan',
          title: 'Senior Software Engineer',
          jobFullTile: 'MindTitan - Senior Software Engineer',
          clientLogo: require('@/assets/images/clients/peoplefy-client-mindtitan.svg'),
          jobAdImg: require('@/assets/images/jobs/mindtitan/senior-engineer.png')
        },
        {
          client: 'MindTitan',
          title: 'Analyst / Product Manager',
          jobFullTile: 'MindTitan - Analyst / Product Manager',
          clientLogo: require('@/assets/images/clients/peoplefy-client-mindtitan.svg'),
          jobAdImg: require('@/assets/images/jobs/mindtitan/product-manager.png')
        },
        {
          client: 'Fractory',
          title: 'Full Stack Developer',
          jobFullTile: 'Fractory - Full Stack Developer',
          clientLogo: require('@/assets/images/clients/peoplefy-client-fractory.svg'),
          jobAdImg: require('@/assets/images/jobs/fractory/full-stack.png')
        },
        {
          client: 'eAgronom',
          title: 'Full Stack Developer',
          jobFullTile: 'eAgronom - Full Stack Developer',
          clientLogo: require('@/assets/images/clients/peoplefy-client-eagronom.svg'),
          jobAdImg: require('@/assets/images/jobs/eAgronom/full-stack-dev.png')
        },
        {
          client: 'eAgronom',
          title: 'Front-end Developer',
          jobFullTile: 'eAgronom - Front-end Developer',
          clientLogo: require('@/assets/images/clients/peoplefy-client-eagronom.svg'),
          jobAdImg: require('@/assets/images/jobs/eAgronom/front-end-dev.jpg')
        },
        {
          client: 'eAgronom',
          title: 'Mobile Developer',
          jobFullTile: 'eAgronom - Mobile Developer',
          clientLogo: require('@/assets/images/clients/peoplefy-client-eagronom.svg'),
          jobAdImg: require('@/assets/images/jobs/eAgronom/mobile-dev.jpg')
        },
        {
          client: 'Devtailor software',
          title: 'Senior developer',
          jobFullTile: 'Devtailor software - Senior developer',
          clientLogo: require('@/assets/images/clients/peoplefy-client-devtailor-software.svg'),
          jobAdImg: require('@/assets/images/jobs/devtailor/senior-dev.jpg')
        },
        {
          client: 'Devtailor software',
          title: 'Project manager',
          jobFullTile: 'Devtailor software - Project manager',
          clientLogo: require('@/assets/images/clients/peoplefy-client-devtailor-software.svg'),
          jobAdImg: require('@/assets/images/jobs/devtailor/project-manager.jpg')
        },
        {
          client: 'Fairown',
          title: 'Software developer',
          jobFullTile: 'Fairown - Software developer',
          clientLogo: require('@/assets/images/clients/peoplefy-client-fairown.svg'),
          jobAdImg: require('@/assets/images/jobs/fairown/software-developer.png')
        },
        {
          client: 'RingIT',
          title: 'Full-Stack Software developer',
          jobFullTile: 'RingIT - Full-Stack Software developer',
          clientLogo: require('@/assets/images/clients/peoplefy-client-ringit.svg'),
          jobAdImg: require('@/assets/images/jobs/ringit/full-stack.png')
        },
      ]
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/scss/home/_portfolio.scss';
</style>